import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"]

  save() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 500)
  }
}
