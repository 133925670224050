import { Controller } from "stimulus";
import $ from 'jquery'
import 'select2'
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ['select']

  connect() {
    if (!this.hasSelectTarget) return

    this.selectJQuery = $(this.selectTarget)
    this.selectJQuery.select2({
      minimumInputLength: 3,
      width: '100%',
      ajax: {
        url: '/tickets_autocomplete.json',
        dataType: 'json',
        data: function (params) {
          return { q: params.term }
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                text: `${item.uid} - ${item.summary}`,
                id: item.id
              }
            })
          };
        },
        delay: 250,
      }
    })

    this.selectJQuery.on('select2:select', function (e) {
      let data = e.params.data;
      const authenticity_token = document.querySelector('meta[name="csrf-token"]').content
      document.querySelector('*[data-behavior="autocomplete"]').value = ""

      fetch(`/tickets/${data.id}/intervals?event=start`, {
        method: "POST",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({authenticity_token: authenticity_token })
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html));
    });
    document.addEventListener('keydown', this.#switchSearch)
  }

  #switchSearch = ({ repeat, metaKey, ctrlKey, key }) => {
    if (repeat || !((metaKey || ctrlKey) && key === 'k')) return

    if (this.selectJQuery.select2('isOpen')) {
      this.selectJQuery.select2('close')
    } else {
      this.selectJQuery.select2('open')
    }
  }
}
