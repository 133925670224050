import consumer from "./consumer"
import debounce from "debounce";
let debounceFunctions = {};

document.addEventListener("turbo:load", () => {
  consumer.subscriptions.create({ channel: "IntervalsChannel" }, {
    received(variables) {
      var event = new Event('refresh');
      let actionedByUserId = variables.actioned_by_user_id.toString()
      let intervalUserId = variables.interval_user_id.toString()
      let currentUserId = document.querySelector('body').dataset.currentUserId
      let refreshContainers = []
      if (actionedByUserId !== intervalUserId) {
        refreshContainers.push(...['#tickets-container', '#intervals-container', '#pairing-container', '#team-dashboard-container'])
        if (intervalUserId === currentUserId) {
          refreshContainers.push(...['#timer-container', '#personal-dashboard-tickets-container', '#personal-dashboard-intervals-container'])
        }
      }
      refreshContainers.forEach((target) => {
        var container = document.querySelector(`${target} div`);
        if (container) {
          if (!debounceFunctions[target]) {
            debounceFunctions[target] = debounce(function () {
              container.dispatchEvent(event)
            }, 5000);
          }
          debounceFunctions[target]();
        }
      });
    },
  });
});
