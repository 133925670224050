import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["select"]

  startTicket(event) {
    const selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex]
    const url = selectedOption.getAttribute('data-url')
    const authenticity_token = document.querySelector('meta[name="csrf-token"]').content

    if (url) {
      const form = document.createElement('form');
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({authenticity_token: authenticity_token })
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html));
    }
  }
}
