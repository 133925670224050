import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [];

  reloadData(event) {
    event.preventDefault();  
    const link = event.target;
    fetch(link.href, { 
      method: "DELETE",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",  
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),  
      },
    })
    .then(response => {
      if (response.ok) {
        response.text().then(html => {
          Turbo.renderStreamMessage(html); 
        });
      }
    })
  }
}