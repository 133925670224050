import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "table"]

    async copyTable() {
      const table = this.tableTarget
      navigator.clipboard.writeText(table.innerText)
    }
}
