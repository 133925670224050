import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  changePage(event) {
    event.preventDefault()
    const url = event.currentTarget.href

    fetch(url, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      },
      credentials: "same-origin"
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
      window.history.pushState({}, '', url)
    })
  }
}
