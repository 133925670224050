import { Controller } from "stimulus";
import $ from 'jquery'
import 'select2'

export default class extends Controller {
  connect() {
    $(this.element).find('*[data-behavior="autocomplete"]').select2({
      minimumInputLength: 3,
      width: 'element',
      ajax: {
        url: '/tickets_autocomplete.json',
        dataType: 'json',
        data: function (params) {
          return { q: params.term }
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                text: `${item.uid} - ${item.summary}`,
                id: item.id
              }
            })
          };
        },
        delay: 250,
      }
    })
  }
}
