import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import $ from "jquery";
export default class extends Controller {
  static targets = ['field'];
  connect() {
    let target = this.fieldTarget;
    if (target.dataset.type === 'time') {
      flatpickr(target, {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        altInput: true,
        minuteIncrement: 1,
        allowInput: true,
        altFormat: 'H:i:s',
        dateFormat: 'H:i:s' // H:i
      })
    }
    if (target.dataset.type === 'datetime') {
      flatpickr(target, {
        enableTime: true,
        altInput: true,
        minuteIncrement: 1,
        allowInput: true,
        altFormat: 'd/m/Y - H:i:S',
        dateFormat: 'Z' // Y-m-d H:i
      })
    }
    if (target.dataset.type === 'date') {
      flatpickr(target, {
        enableTime: false,
        altInput: true,
        allowInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'd/m/Y',
        onReady: function(dateObj, dateStr, instance) {
          $('.flatpickr-calendar').each(function() {
            var $this = $(this);
            if ($this.find('.flatpickr-clear').length < 1) {
              $this.append('<div class="flatpickr-clear btn-white cursor-pointer">Clear</div>');
              $this.find('.flatpickr-clear').on('click', function() {
                instance.clear();
                instance.close();
              });
            }
          });
        }
      });
    }
  }
}
