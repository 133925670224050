import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [ "results", "form" ]

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 500)
  }

  handleResults(event) {
    let [_data, _status, xhr] = event.detail;
    const newUrl = xhr.responseURL;
    history.pushState({}, null, newUrl);
    Turbo.renderStreamMessage(xhr.response);
  }
}
