/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs";

require("@hotwired/turbo-rails")
require("./channels")
require("jquery")
require("easy-autocomplete")
require('select2')

// Stimulus controllers
import "controllers"

import SlimSelect from 'slim-select'

function bindSelects() {
  document.querySelectorAll(".slim-select").forEach(select => {
    const self = select;
    const slimSelect = new SlimSelect({
      select: self,
      beforeOnChange: (info) => {
        if (self.dataset.allowall === "true" && info.length > 1) {
          const selectedValues = [...self.selectedOptions].map(option => option.value);
          if (selectedValues.includes("")) {
            slimSelect.set(info.filter(o => o.value != "").map(o => o.value));
            return false;
          } else if (info.map(o => o.value).includes("")) {
            slimSelect.set([""]);
            return false;
          }
        }
      }
    })
    if (select.dataset.allselected === "true") {
      slimSelect.set([""]);
    } 

    const clearButton = select.parentElement.querySelector(".btn.clear")
    if (clearButton) clearButton.onclick = () => slimSelect.set([])
  });
}

document.addEventListener("turbo:load", function () {
  bindSelects()
})
// Start Rails UJS
Rails.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Styles
// These are imported separately for faster Webpack recompilation
// https://rubyyagi.com/solve-slow-webpack-compilation/
