import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "scheduled" ]

  enable(event) {
    event.preventDefault();
    let button = $(event.target);
    let target;
    if (event.target.tagName === 'BUTTON') {
      button.hide();
      target = button
    } else {
      $(button.parent()).hide();
      target = $(button.parent())
    }
    $(target.data('toggleTarget')).show();
    $(target.data('enableTarget')).val(true);
  }

  disable(event) {
    event.preventDefault();
    let button = $(event.target.parentElement.parentElement.parentElement);
    button.hide();

    const parentDataContainer = event.target.parentElement
    this.hideVisualElements(parentDataContainer);
    this.disableFormValue();
    
  }

  hideVisualElements(parentDataContainer) {
    $(parentDataContainer.dataset.toggleTarget).show();
    $(parentDataContainer.dataset.enableTarget).val(false);
  }

  disableFormValue() {
    this.scheduledTarget.value = false
  }
}
