import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["time"]

  connect() {
    const startTime = new Date(this.element.dataset.time);
    clearInterval(this.timeout)

    this.timeout = setInterval((element) => {
      const currentTime = new Date();
      let counter;
      if (startTime.getTime() < currentTime.getTime()) {
        counter = this.splitTime(Math.abs(startTime.getTime() - currentTime.getTime()) / 1000);
      } else {
        counter = this.splitTime(Math.abs(currentTime.getTime() - startTime.getTime()) / 1000);
      }

      const targets = element.dataset.targets.split(' ');
      targets.forEach((target) => {
        let formattedTime = counter;
        if ($(target).data('format') === 'short') {
          formattedTime = `${formattedTime[0]}h${formattedTime[1]}m${formattedTime[2]}s`
        } else if ($(target).data('format') === 'long') {
          formattedTime = `${formattedTime[0]} hrs ${formattedTime[1]} mins ${formattedTime[2]} secs`
        } else if ($(target).data('format') === 'min') {
          formattedTime = `${formattedTime[0]}h ${formattedTime[1]}m`
        } else {
          formattedTime = `${formattedTime[0]}:${formattedTime[1]}:${formattedTime[2]}`
        }

        if (startTime.getTime() < currentTime.getTime()) {
          $(target).text(`${$(target).data('prepend') || ''} -${formattedTime})`);
        } else {
          $(target).text(`${$(target).data('prepend') || ''} ${formattedTime})`);
        }
      });
    }, 500, this.element, startTime)
  }

  disconnect() {
    clearInterval(this.timeout)
  }

  splitTime(time) {
    var sec_num = parseInt(time, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return [hours, minutes, seconds];
  }
}
